import { useDispatch, useSelector } from "react-redux";
import {
  Course,
  Module,
  ParticipantTest,
  Section,
  ParticipantSection,
} from "@app/types/interfaces";
import {
  SetCurrentCourse,
  SetCurrentParticipant,
} from "@app/modules/courses/store/actions";
import {
  bulkDeleteParticipantTests,
  getCourse,
  getParticipant,
  updateParticipantProgress,
} from "@app/modules/courses/services";
import { useEffect, useState } from "react";
import {
  selectCurrentCourse,
  selectCurrentCourseLoaded,
} from "@app/modules/courses/store/selectors";
import { Status } from "@app/types/enums";
import { differenceBy } from "lodash";

export const useLoadCurrentCourse = (courseId: number, userId: string) => {
  const dispatch = useDispatch();
  const [currentCourseLoading, setCurrentCourseLoading] = useState(true);

  const currentCourseLoaded = useSelector(selectCurrentCourseLoaded);
  const loadedCourse = useSelector(selectCurrentCourse);

  useEffect(() => {
    if (currentCourseLoaded) {
      if (loadedCourse.id === courseId) {
        //we can have a course saved as current, but it might actually be the one the user previously clicked on
        setCurrentCourseLoading(false);
        return;
      }
    }
    getCurrentCourse(courseId, userId);
  }, []);

  const getCurrentCourse = async (courseId: number, userId: string) => {
    try {
      const currentCourse: Course = await getCourse(courseId);
      currentCourse.modules
        .sort((module1, module2) => (module1.order > module2.order ? 1 : -1))
        .map((module) =>
          module.sections.sort((section1, section2) =>
            section1.order > section2.order ? 1 : -1
          )
        );

      dispatch(SetCurrentCourse(currentCourse));

      const { results } = await getParticipant(courseId, userId);

      if (results.length !== 0) {
        const filteredParticipantSections = results[0].sections.filter(
          (participantSection: ParticipantSection) =>
            currentCourse.modules.find((module: Module) =>
              module.sections.find(
                (section: Section) => section.id === participantSection.section
              )
            )
        );
        const filteredParticipantTests = results[0].tests?.filter(
          (participantTest: ParticipantTest) =>
            currentCourse.modules.find(
              (module: Module) => module.test?.id === participantTest.test
            ) || currentCourse.test?.id === participantTest.test
        );

        const testsThatShouldBeDeleted = differenceBy(
          results[0].tests,
          filteredParticipantTests,
          (participantTest: ParticipantTest) => participantTest.id
        );

        const participant = {
          ...results[0],
          sections: filteredParticipantSections,
          tests: filteredParticipantTests,
        };

        let finishedDuration = 0,
          totalDuration = 0;
        currentCourse.modules.forEach((_module: Module) =>
          _module.sections.forEach((_section: Section) => {
            const participantSection = participant.sections.find(
              (_pSection: ParticipantSection) =>
                _pSection.section === _section.id
            );
            if (
              participantSection &&
              participantSection.status === Status.FINISHED
            ) {
              finishedDuration += _section.duration;
            }
            totalDuration += _section.duration;
          })
        );
        if (
          participant.duration !== finishedDuration ||
          participant.progress !==
            Math.floor((finishedDuration / totalDuration) * 100)
        ) {
          await updateParticipantProgress(
            participant.id,
            finishedDuration,
            Math.floor((finishedDuration / totalDuration) * 100)
          );
        }

        if (testsThatShouldBeDeleted?.length) {
          await bulkDeleteParticipantTests({
            id__in: testsThatShouldBeDeleted
              .map((participantTest: ParticipantTest) => participantTest.id)
              .join(","),
          });
        }

        participant.duration = finishedDuration;
        participant.progress = Math.floor(
          (finishedDuration / totalDuration) * 100
        );

        dispatch(SetCurrentParticipant(participant));
      } else {
        dispatch(SetCurrentParticipant(null));
      }

      setCurrentCourseLoading(false);
    } catch (error) {
      setCurrentCourseLoading(false);
      throw error;
    }
  };

  return {
    currentCourseLoading,
  };
};

import React from "react";
import { Colors } from "@app/types/enums";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import { TitleFormat } from "@app/common/components";

interface PageLayoutProps {
  title?: string;
  subtitle?: string;
  backButton?: boolean;
  cardHeight?: string;
  children: any;
}

export const PageLayout = ({
  title,
  subtitle,
  backButton,
  children,
  cardHeight,
  ...props
}: PageLayoutProps & SxProps) => {
  return (
    <Box
      className="layout-container"
      sx={{
        color: Colors.BLACK,
        display: "flex",
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
        justifyContent: backButton ? "start" : "space-around",
        zIndex: 1,
        pb: 9,
        // pt: 5,
        ...props,
      }}
    >
      <TitleFormat backButton={backButton} title={title} subtitle={subtitle} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: cardHeight ? cardHeight : backButton ? "90vh" : "80vh",
          width: "100%",
          backgroundColor: Colors.WHITE,
          borderRadius: 4,
          overflowY: "scroll",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

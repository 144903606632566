import { Box, Button, Typography } from "@mui/material";
import { Colors, Icons, Routes } from "@app/types/enums";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SxProps } from "@mui/system";

interface TitleFormatProps {
  backButton?: boolean;
  title?: string;
  subtitle?: string;
}

export const TitleFormat = ({
  backButton,
  title,
  subtitle,
  ...props
}: TitleFormatProps & SxProps) => {
  const navigate = useNavigate();

  return (
    <Box sx={props}>
      {backButton && (
        <Button
          onClick={() => navigate(Routes.COURSES)}
          variant="text"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            width: "fit-content",
            mt: 4,
            mb: 3,
          }}
        >
          <img src={Icons.BACK_ARROW} alt={"back arrow"} />
          <Typography
            sx={{
              font: "normal normal 400 14px/21px Open_Sans",
              pl: 1,
              pt: "2px",
              color: Colors.TEXT_PRIMARY,
            }}
          >
            Mergi înapoi
          </Typography>
        </Button>
      )}

      {title && (
        <Box pl={2} mb={4}>
          <Typography
            variant={"h1"}
            color={Colors.BLACK}
            sx={{ mt: backButton && 4, pb: 1 }}
          >
            {title}
          </Typography>
          <Typography variant={"subtitle1"} color={Colors.BLACK}>
            {subtitle}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectCurrentUser } from "@app/modules/auth/store/selectors";
import { getUser as getUserService } from "@app/auth/services";
import { SetUser } from "@app/auth/store/actions";
import { getTokens } from "@app/api";
import { User } from "@app/types/interfaces";

export const useLoadUser = () => {
  const user = useSelector(selectCurrentUser);
  const [userLoading, setUserLoading] = useState(true);
  const dispatch = useDispatch();
  const { accessToken } = getTokens();

  useEffect(() => {
    if (!user && accessToken) {
      getUser();
    } else {
      setUserLoading(false);
    }
  }, []);

  const getUser = async () => {
    try {
      const user: User = await getUserService();
      dispatch(SetUser(user));
      setUserLoading(false);
    } catch (error) {
      setUserLoading(false);
      throw error;
    }
  };

  return { user, userLoading };
};

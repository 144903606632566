import { createTheme } from "@mui/material/styles";
import { Colors } from "@app/types/enums";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.PRIMARY,
      light: Colors.PRIMARY_LIGHT,
      dark: Colors.PRIMARY_DARK,
    },
    secondary: {
      main: Colors.SECONDARY,
      light: Colors.SECONDARY_LIGHT,
      dark: Colors.SECONDARY_DARK,
    },
    error: {
      main: Colors.ERROR,
      light: Colors.ERROR_LIGHT,
      dark: Colors.ERROR_DARK,
    },
    success: {
      main: Colors.SUCCESS,
      light: Colors.SUCCESS_LIGHT,
      dark: Colors.SUCCESS_DARK,
    },
    text: {
      primary: Colors.TEXT_PRIMARY,
      secondary: Colors.WHITE,
      disabled: Colors.DISABLED,
    },
    common: {
      white: Colors.WHITE,
      black: Colors.BLACK,
    },
    grey: {
      "50": "#FAFAFA",
      "100": "#F5F5F5",
      "200": "#EEEEEE",
      "300": "#E0E0E0",
      "400": "#BDBDBD",
      "500": "#9E9E9E",
      "600": "#757575",
      "700": "#616161",
      "800": "#424242",
      "900": "#212121",
    },
  },
  typography: {
    fontFamily: "MyriadPro",
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: Colors.WHITE,
        },
        barColorPrimary: {
          backgroundColor: Colors.SUCCESS,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: Colors.TEXT_PRIMARY,
          fontFamily: "MyriadPro",
          fontWeight: "600",
          fontStyle: "normal",
        },
        h1: {
          fontWeight: "700",
          fontSize: "24px",
          lineHeight: "36px",
        },
        h2: {
          fontSize: "20px",
          lineHeight: "30px",
        },
        h3: {
          fontSize: "16px",
          lineHeight: "24px",
        },
        subtitle1: {
          fontWeight: "300",
          fontSize: "16px",
          lineHeight: "24px",
        },
        subtitle2: {
          fontWeight: "300",
          fontSize: "14px",
          lineHeight: "21px",
        },
        body1: {
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
        },
        body2: {
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "18px",
        },
        caption: {
          fontWeight: "300",
          fontSize: "12px",
          lineHeight: "18px",
        },
        button: {
          fontSize: "16px",
          lineHeight: "24px",
          letterSpacing: "0.15px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          font: "normal normal 400 14px/21px MyriadPro !important",
          backgroundColor: Colors.LIGHT_GREY,
          borderRadius: "5px",
          "&.Mui-focused": {
            backgroundColor: `${Colors.WHITE} !important`,
            border: `1px solid ${Colors.SECONDARY}`,
          },
          "&.Mui-error": {
            border: `1px solid ${Colors.ERROR}`,
            backgroundColor: `${Colors.WHITE} !important`,
          },
          "&.Mui-error fieldset": {
            borderColor: `${Colors.ERROR} !important`,
            backgroundColor: `${Colors.WHITE} !important`,
          },
          "&.MuiFilledInput-underline:before": {
            borderBottom: "none",
          },
          "&.MuiFilledInput-underline:hover:before": {
            borderBottom: "none",
          },
          "&.MuiFilledInput-underline:after": {
            borderBottom: "none",
          },
          "&.Mui-disabled": {
            backgroundColor: `${Colors.GREY_200} !important`,
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          font: "normal normal 400 14px/21px MyriadPro !important",
          color: Colors.TEXT_PRIMARY,
          "&.Mui-focused": {
            color: `${Colors.SECONDARY} !important`,
            borderColor: "red !important",
          },
          "&.Mui-error": {
            color: `${Colors.ERROR} !important`,
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        outlined: {
          font: "normal normal 600 16px/24px MyriadPro !important",
          letterSpacing: "0.15 !important",
          maxHeight: "44px !important",
          border: `1px solid ${Colors.GREY_400}`,
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
          color: Colors.TEXT_PRIMARY,
          flex: "none",
          order: "0",
          alignSelf: "stretch",
          flexGrow: "1",
          margin: "0px 0px",
          boxShadow: "none",
          textTransform: "none",
          "&:hover": {
            background: Colors.GREY_200,
          },
          "&:disabled": {
            color: Colors.TEXT_SECONDARY,
            backgroundColor: Colors.GREY_300,
          },
        },
        contained: {
          font: "normal normal 600 16px/24px MyriadPro !important",
          letterSpacing: "0.15 !important",
          maxHeight: "44px !important",
          border: "none",
          boxSizing: "border-box",
          borderRadius: "4px",
          color: Colors.WHITE,
          flex: "none",
          order: "0",
          alignSelf: "stretch",
          flexGrow: "1",
          margin: "0px 0px",
          textTransform: "none",
          boxShadow: "none",
          "&:disabled": {
            border: "none",
            backgroundColor: Colors.GREY_200,
            color: Colors.TEXT_SECONDARY,
          },
        },
        text: {
          font: "normal normal 600 16px/24px MyriadPro !important",
          letterSpacing: "0.15 !important",
          maxHeight: "44px !important",
          border: "none",
          boxSizing: "border-box",
          color: Colors.PRIMARY,
          flex: "none",
          order: "0",
          alignSelf: "stretch",
          flexGrow: "1",
          margin: "0px 0px",
          textTransform: "none",
          boxShadow: "none",
        },
      },
    },
  },
});

export default theme;

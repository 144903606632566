import { ParticipantTest, ParticipantAnswer } from "@app/types/interfaces";
import { api, wensApi } from "@app/api";

const participantTestPath = "/records/participant_test";
const participantAnswerPath = "/records/participant_answer";

const testQueryParams = {
  datafy_fields:
    "id,status,score,participant_answers.id,participant_answers.answer,participant_answers.question",
};

export const updateStatusOfParticipantTest = (
  pTestId: number,
  status: string
) => {
  return api.patch<void, ParticipantTest>(`${participantTestPath}/${pTestId}`, {
    status,
  });
};

export const setParticipantAnswersForTest = (answers: ParticipantAnswer[]) => {
  return api.post<void, ParticipantAnswer[]>(
    `${participantAnswerPath}/bulk`,
    answers
  );
};

export const bulkDeleteParticipantTests = (queryParams) => {
  return api.delete<void, void>(`${participantTestPath}/bulk`, {
    params: queryParams,
  });
};

export const updateParticipantTest = (test: ParticipantTest) => {
  return api.patch<void, ParticipantTest>(
    `${participantTestPath}/${test.id}`,
    test
  );
};

export const getParticipantTest = (
  testId: number
): Promise<ParticipantTest> => {
  return api.get(`${participantTestPath}/${testId}`, {
    params: testQueryParams,
  });
};

export const setParticipantTest = (test: ParticipantTest) => {
  return api.post<void, ParticipantTest>(`${participantTestPath}`, test);
};

export const computeTestScore = (test: ParticipantTest) => {
  return wensApi.post<void>(`tests/compute-score`, test);
};

import React from "react";
import { Box } from "@mui/material";
import { Colors } from "@app/types/enums";
import { Footer, Header } from "@app/modules/core/components";

export const Layout = ({ children }: any) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
        minHeight: "100vh",
        backgroundColor: Colors.LIGHT_GREY,
        position: "relative",
      }}
    >
      <img
        src={"/images/background-layout.svg"}
        style={{
          position: "absolute",
          zIndex: 0,
        }}
      />
      <Header />
      <main className="full-width">{children}</main>
      <Footer />
    </Box>
  );
};

import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Colors } from "@app/types/enums";
import { PageLayout } from "@app/common/components";
import { Support, User } from "@app/types/interfaces";

interface ProfileDisplayProps {
  user: User;
  support: Support;
  loading: boolean;
}

export const ProfileDisplay = ({
  user,
  support,
  loading,
}: ProfileDisplayProps) => {
  return (
    <PageLayout
      title={"Profilul tău"}
      subtitle={
        "Aceste date nu pot fi schimbate decât de administratorul platformei"
      }
      backButton={true}
      cardHeight={"50vh"}
      height={"100%"}
      pb={9}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          width: "50%",
          pl: 3,
          pt: 3,
        }}
      >
        <Typography variant={"h2"} color={Colors.BLACK} sx={{ mb: 1.5 }}>
          Detalii personale
        </Typography>
        {[user.lastName, user.firstName, user.email].map((detail, index) => (
          <TextField
            key={index}
            disabled={true}
            label={["Nume", "Prenume", "Email"][index]}
            type="text"
            variant="filled"
            value={detail}
            sx={{ my: 1.5, width: "72%" }}
          />
        ))}
      </Box>
      <Box
        sx={{
          height: 113,
          width: 2,
          bgcolor: Colors.GREY_300,
          mt: 7,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          width: "50%",
          pl: 12,
          pr: 3,
          pt: 3,
        }}
      >
        <Typography variant={"h2"} color={Colors.TEXT_PRIMARY}>
          Ai nevoie de ajutor?
        </Typography>
        <Typography
          variant={"subtitle2"}
          color={Colors.TEXT_PRIMARY}
          sx={{ my: 2 }}
        >
          Contactează-ne folosind informațiile de mai jos
        </Typography>
        <Typography
          variant={"body1"}
          color={Colors.TEXT_PRIMARY}
          sx={{ my: 1 }}
        >
          {`Email: ${loading ? "loading..." : support?.email || ""}`}
        </Typography>
        <Typography variant={"body1"} color={Colors.TEXT_PRIMARY}>
          {`Nr. de telefon: ${loading ? "loading..." : support?.phone || ""}`}
        </Typography>
      </Box>
    </PageLayout>
  );
};

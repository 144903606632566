import React, { useState } from "react";
import { LoginPageDisplay } from "@app/modules/auth/components";
import { useAuth } from "@app/modules/auth/hooks";
import { LoginFormData } from "@app/types/interfaces";
import { removeTokens } from "@app/api";

export const LoginPage = () => {
  const [responseMessage, setResponseMessage] = useState("");
  const { login, loading } = useAuth();

  const onAuthenticate = async ({ email, password }: LoginFormData) => {
    try {
      removeTokens();
      await login(email, password);
    } catch (error) {
      setResponseMessage("Email-ul sau parola sunt greșite");
    }
  };

  return (
    <LoginPageDisplay
      onAuthenticate={onAuthenticate}
      message={responseMessage}
      loading={loading}
    />
  );
};

import { createAction } from "typesafe-actions";
import {
  Participant,
  ParticipantTest,
  ParticipantSection,
} from "@app/types/interfaces";

const SET_PARTICIPANTS_LIST = "[Participant] Load Participants List";
const ADD_PARTICIPANT = "[Participant] Add Participant";
const SET_CURRENT_PARTICIPANT = "[Participant] Set Current Participant";
const RESET_PARTICIPANTS_STORE = "[Participant] Reset";
const UPDATE_PARTICIPANT = "[Participant] Update";
const UPDATE_SECTION = "[Participant] Update section";
const UPDATE_TEST = "[Participant] Update test";

export const SetParticipantsList = createAction(
  SET_PARTICIPANTS_LIST,
  (participants: Participant[]) => participants
)<Participant[]>();
SetParticipantsList;

export const AddParticipant = createAction(
  ADD_PARTICIPANT,
  (participant: Participant) => participant
)<Participant>();
AddParticipant;

export const SetCurrentParticipant = createAction(
  SET_CURRENT_PARTICIPANT,
  (participant: Participant) => participant
)<Participant>();
SetCurrentParticipant;

export const ResetParticipantsStore = createAction(RESET_PARTICIPANTS_STORE)();
ResetParticipantsStore;

export const UpdateParticipant = createAction(
  UPDATE_PARTICIPANT,
  (participant: Participant) => participant
)<Participant>();
UpdateParticipant;

export const UpdateParticipantSection = createAction(
  UPDATE_SECTION,
  (section: ParticipantSection) => section
)<ParticipantSection>();
UpdateParticipantSection;

export const UpdateParticipantTest = createAction(
  UPDATE_TEST,
  (test: ParticipantTest) => test
)<ParticipantTest>();
UpdateParticipantTest;

import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentCourse } from "@app/modules/courses/store/selectors";
import { Colors, Icons, Status } from "@app/types/enums";
import {
  Participant,
  ParticipantTest,
  Section,
  Test,
  ParticipantSection,
} from "@app/types/interfaces";
import Lottie from "lottie-react";
import animation from "@app/common/animations/finish_test_animation.json";
import CircularProgress from "@mui/material/CircularProgress";

interface TestFinishedProps {
  onNextChapterButtonClick: () => void;
  setSubmitted: (submitted: boolean) => void;
  setAnswers: (answers: { [questionId: number]: number[] }) => void;
  test: Test;
  participant: Participant;
  loading: boolean;
  nextContent: ParticipantTest | ParticipantSection;
}

export const TestFinished = ({
  onNextChapterButtonClick,
  setSubmitted,
  setAnswers,
  test,
  participant,
  loading,
  nextContent,
}: TestFinishedProps) => {
  const course = useSelector(selectCurrentCourse);
  const finalized =
    participant.progress === 100 &&
    participant?.tests?.filter((test) => test.status !== Status.FINISHED)
      .length === 0;

  const itemList =
    test.id === course.test.id
      ? course.modules.map((module) =>
          module.sections
            .filter((_section: Section) =>
              participant.sections.find(
                (_pSection: ParticipantSection) =>
                  _pSection.section === _section.id
              )
            )
            .map((section, index) => (
              <Typography
                key={index}
                variant={"body1"}
                color={Colors.TEXT_SECONDARY}
                sx={{ py: 0.5 }}
              >
                {section.title}
              </Typography>
            ))
        )
      : course.modules
          .find((module) => module.test.id === test.id)
          .sections.filter((_section: Section) =>
            participant.sections.find(
              (_pSection: ParticipantSection) =>
                _pSection.section === _section.id
            )
          )
          .map((section, index) => (
            <Typography
              key={index}
              variant={"body2"}
              color={Colors.TEXT_SECONDARY}
              sx={{ py: 0.5 }}
            >
              {section.title}
            </Typography>
          ));

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <Box>
        <Typography variant={"h2"} color={Colors.TEXT_PRIMARY}>
          Felicitări!
        </Typography>
        <Typography
          variant={"body1"}
          color={Colors.TEXT_PRIMARY}
          sx={{ py: 2 }}
        >
          {test.id === course.test.id
            ? "Ați finalizat acest curs!"
            : "Ați finalizat acest modul!"}
        </Typography>
        <Divider
          color={Colors.GREY_300}
          sx={{ width: 113, height: 2, my: 1 }}
        />
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          overflowX: "scroll",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "50%",
            overflowX: "scroll",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
          }}
        >
          <Typography variant={"h3"} color={Colors.TEXT_PRIMARY}>
            Capitole
          </Typography>
          {itemList}
        </Box>
        <Box
          sx={{
            width: "50%",
            position: "relative",
            top: { md: "-60px", lg: "-100px" },
          }}
        >
          <Lottie animationData={animation} loop={true} />
        </Box>
      </Box>
      <Button
        disabled={loading}
        onClick={async () => {
          setAnswers([]);
          await onNextChapterButtonClick();
          setSubmitted(false);
        }}
        variant="contained"
        color="secondary"
        sx={{
          maxWidth: "304px",
          px: 2,
          py: 1,
        }}
      >
        {loading ? (
          <CircularProgress sx={{ color: Colors.WHITE }} size={"20px"} />
        ) : (
          <Typography
            variant={"button"}
            sx={{
              textTransform: "none",
              color: Colors.WHITE,
              pr: 2,
            }}
            fontWeight={700}
          >
            {finalized && !nextContent
              ? "Finalizează"
              : finalized && nextContent && nextContent["isTest"]
              ? "Începe testul final"
              : "Începe următorul modul"}
          </Typography>
        )}
        {!loading && (
          <img
            src={Icons.RIGHT_ARROW}
            style={{
              filter:
                "invert(100%) sepia(100%) saturate(1%) hue-rotate(211deg) brightness(110%) contrast(101%)",
            }}
          />
        )}
      </Button>
    </Box>
  );
};

import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Colors } from "@app/types/enums";
import React, { useEffect, useState } from "react";

interface CheckboxProps {
  disabled: boolean;
  value: number | string;
  color: Colors;
  text: string;
  checked?: boolean;
}

export const CheckboxInput = (props: CheckboxProps) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <FormControlLabel
      disabled={props.disabled}
      className={"full-width"}
      value={props.value}
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          style={{
            color: props.disabled ? Colors.DISABLED : Colors.TEXT_PRIMARY,
          }}
        />
      }
      label={
        <Typography
          className={"word-wrap"}
          variant={"body1"}
          color={props.color}
        >
          {props.text}
        </Typography>
      }
      sx={{ py: 0.5, pb: 1.5 }}
    />
  );
};

import React, { useEffect, useRef, useState } from "react";
import { Colors, Icons, Routes, Status } from "@app/types/enums";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Course,
  Participant,
  ParticipantTest,
  Section,
  Test,
  ParticipantSection,
} from "@app/types/interfaces";
import { useSelector } from "react-redux";

import {
  selectAllSections,
  selectAllTests,
  selectContentInProgress,
  selectCurrentParticipant,
  selectNextContent,
  selectParticipantCourseTest,
} from "@app/modules/courses/store/selectors";
import { ModuleRow, TestDisplay } from "@app/modules/courses/components";
import { DeletedParticipant } from "@app/common/components";
import {
  useParticipantSections,
  useParticipantTests,
} from "@app/modules/courses/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import { finalizedCourse } from "@app/common/utilities";

interface DividedPageLayoutProps {
  course: Course;
}

export const DividedPageLayout = ({ course }: DividedPageLayoutProps) => {
  const navigate = useNavigate();

  const [answers, setAnswers] = useState<{ [questionId: number]: number[] }>(
    []
  );
  const [submitted, setSubmitted] = useState(false);
  const participant: Participant = useSelector(selectCurrentParticipant);
  const finalized = finalizedCourse(participant, course);

  const ref = useRef(null);
  const allSections = useSelector(selectAllSections);
  const allTests = useSelector(selectAllTests);
  const contentInProgress = useSelector(selectContentInProgress);
  const [currentParticipantTest, setCurrenParticipantTest] = useState(null);
  const [currentParticipantSection, setCurrentParticipantSection] =
    useState(null);
  const [test, setTest] = useState<Test>(
    finalized &&
      course?.modules?.length &&
      !course?.modules[0]?.sections?.length
      ? course.modules[0].test
      : null
  );
  const [loading, setLoading] = useState(false);
  const [currentSection, setCurrentSection] = useState<Section>(
    finalized ? course?.modules[0]?.sections[0] : null
  );
  const sectionsFinished = participant?.progress === 100;

  const {
    updateParticipantSectionsForStartingNextChapter,
    createPSection,
    deleteParticipantSections,
  } = useParticipantSections();

  const { createParticipantTest, deleteParticipantTests } =
    useParticipantTests();
  const nextContent = useSelector(selectNextContent(participant));
  const participantCourseTest = useSelector(
    selectParticipantCourseTest(course)
  );

  useEffect(() => {
    if (contentInProgress) {
      if (contentInProgress["isTest"]) {
        setCurrenParticipantTest(contentInProgress);
        setTest(allTests[(contentInProgress as ParticipantTest).test]);
        setCurrentParticipantSection(null);
        setCurrentSection(null);
      } else {
        setCurrentParticipantSection(contentInProgress);
        setCurrentSection(
          allSections[(contentInProgress as ParticipantSection).section]
        );
        setTest(null);
        setCurrenParticipantTest(null);
      }
    } else {
      setCurrentParticipantSection(null);
      setCurrenParticipantTest(null);
    }
  }, [contentInProgress]);

  useEffect(() => {
    // In case there are multiple sections/test in progress (could be the case when a module which is in progress is unassigned and assigned back after a while)
    if (contentInProgress) {
      if (contentInProgress["isTest"]) {
        const tests = testsInProgress();
        if (tests.length) {
          deleteParticipantTests(tests);
        }
      } else {
        const sections = sectionsInProgress();
        if (sections.length) {
          deleteParticipantSections(sections);
        }
      }
    }
    if (!contentInProgress && participant && nextContent) {
      startNextContent();
    }
  }, []);

  useEffect(() => {
    if (ref.current?.parentElement) {
      ref.current.parentElement.scrollTop = 0;
    }
  }, [currentSection]);

  const modulesList = course.modules.map(
    (module, index) =>
      module.sections && (
        <ModuleRow
          key={index}
          module={module}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          finalized={sectionsFinished}
          test={test}
          setTest={setTest}
          testInProgress={Object.keys(answers).length !== 0}
          setAnswers={setAnswers}
          submitted={submitted}
        />
      )
  );

  const onNextChapterButtonClick = async () => {
    setLoading(true);
    if (!nextContent) {
      setLoading(false);
      navigate(Routes.COURSES + "/" + course.id);
    }

    if (currentParticipantSection) {
      await updateParticipantSectionsForStartingNextChapter(
        currentSection.duration,
        currentParticipantSection,
        nextContent && !nextContent["isTest"] ? nextContent : null
      );
      if (nextContent && nextContent["isTest"]) {
        await createParticipantTest(nextContent);
      }
    } else if (!currentParticipantSection && !currentParticipantTest) {
      if (nextContent) {
        await startNextContent();
      }
    }
    setLoading(false);
  };

  const startNextContent = async () => {
    if (!nextContent["isTest"]) {
      await createPSection(nextContent);
    } else {
      await createParticipantTest(nextContent);
    }
  };

  const sectionsInProgress = () => {
    return participant?.sections?.filter(
      (participantSection: ParticipantSection) =>
        participantSection.section !==
          (contentInProgress as ParticipantSection).section &&
        participantSection.status === Status.IN_PROGRESS
    );
  };

  const testsInProgress = () => {
    return participant?.tests?.filter(
      (participantTest: ParticipantTest) =>
        participantTest.test !== (contentInProgress as ParticipantTest).test &&
        participantTest.status === Status.IN_PROGRESS
    );
  };

  if (!participant) {
    return <DeletedParticipant />;
  }

  return (
    <Box
      className="layout-container"
      sx={{
        color: Colors.BLACK,
        display: "flex",
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
        justifyContent: "center",
        pb: 9,
        zIndex: 1,
      }}
    >
      <Button
        onClick={() => {
          setLoading(false);
          navigate(Routes.COURSES + "/" + course.id);
        }}
        variant="text"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          width: "fit-content",
          mt: 4,
          mb: 3,
        }}
      >
        <img src={Icons.BACK_ARROW} alt={"back arrow"} />
        <Typography
          sx={{
            font: "normal normal 400 14px/21px Open_Sans",
            pl: 1,
            pt: "2px",
            color: Colors.TEXT_PRIMARY,
          }}
        >
          Mergi înapoi
        </Typography>
      </Button>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "90vh",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            width: "32%",
            borderRadius: 5,
            backgroundColor: Colors.WHITE,
            p: 4,
          }}
        >
          <Box
            sx={{
              overflowY: "auto",
            }}
          >
            <Typography variant={"subtitle1"} color={Colors.TEXT_PRIMARY}>
              Program de învățare
            </Typography>
            <Divider
              color={Colors.GREY_300}
              sx={{ width: 113, height: 2, mt: 2, mb: 3 }}
            />
            {modulesList}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "start",
                width: "100%",
                cursor:
                  participantCourseTest &&
                  participantCourseTest.status !== Status.NOT_STARTED &&
                  "pointer",
              }}
              onClick={() =>
                participantCourseTest &&
                participantCourseTest.status !== Status.NOT_STARTED &&
                setTest(course.test)
              }
            >
              <img
                src={`${
                  !participantCourseTest
                    ? Icons.NOT_STARTED
                    : participantCourseTest.status === Status.IN_PROGRESS
                    ? Icons.RIGHT_ARROW
                    : participantCourseTest.status === Status.NOT_STARTED
                    ? Icons.NOT_STARTED
                    : Icons.FINISHED_MODULE
                }`}
                style={{ maxWidth: "20px", height: "auto", paddingTop: 1 }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  pl: 1,
                }}
              >
                <Typography color={Colors.TEXT_PRIMARY} sx={{ pb: 0.5 }}>
                  <span className={"limit-rows-to-2 sidenav-text"}>
                    {course.test.title}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "start",
            width: "66%",
            borderRadius: 5,
            backgroundColor: Colors.WHITE,
            p: 4,
          }}
        >
          <Box
            sx={{
              height:
                test ||
                currentParticipantSection?.section !== currentSection?.id ||
                sectionsFinished
                  ? "100%"
                  : "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              overflowY: "auto",
              pr: 3,
            }}
          >
            {!test && (
              <Typography
                variant={"h2"}
                color={Colors.TEXT_PRIMARY}
                sx={{ pb: 4 }}
              >
                {currentSection?.title}
              </Typography>
            )}
            {!test ? (
              <div
                className={"fit-content"}
                style={{
                  font: "normal normal 400 14px/21px MyriadPro",
                }}
                ref={ref}
                dangerouslySetInnerHTML={{
                  __html: currentSection?.content,
                }}
              />
            ) : (
              <TestDisplay
                pTest={currentParticipantTest}
                test={test}
                onNextChapterButtonClick={onNextChapterButtonClick}
                answers={answers}
                setAnswers={setAnswers}
                submitted={submitted}
                setSubmitted={setSubmitted}
                buttonLoading={loading}
                nextContent={
                  nextContent as ParticipantTest | ParticipantSection
                }
              />
            )}
          </Box>
          {!test &&
            currentParticipantSection?.section === currentSection?.id &&
            !sectionsFinished && (
              <Box
                sx={{
                  height: "10%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "end",
                }}
              >
                <Button
                  disabled={loading}
                  onClick={onNextChapterButtonClick}
                  variant="contained"
                  color="secondary"
                  sx={{
                    maxWidth: "306px",
                    display: "flex",
                    alignItems: "center",
                    px: 4,
                    py: 1,
                  }}
                >
                  <Typography
                    variant={"button"}
                    sx={{
                      textTransform: "none",
                      color: Colors.WHITE,
                      pr: 2,
                    }}
                    fontWeight={700}
                  >
                    {loading ? (
                      <CircularProgress
                        sx={{ color: Colors.WHITE, mb: -0.5 }}
                        size={"20px"}
                      />
                    ) : nextContent && nextContent["isTest"] ? (
                      "Începe testul final de modul"
                    ) : (
                      "Începe următorul capitol"
                    )}
                  </Typography>
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};

import { getType } from "typesafe-actions";
import { Participant, ParticipantTest } from "@app/types/interfaces";
import {
  AddParticipant,
  ResetParticipantsStore,
  SetCurrentParticipant,
  SetParticipantsList,
  UpdateParticipant,
  UpdateParticipantSection,
  UpdateParticipantTest,
} from "@app/modules/courses/store/actions/participants.actions";
import { ParticipantSection } from "@app/types/interfaces";

export interface ParticipantsReducerState {
  participants: { [id: number]: Participant };
  participantsLoaded: boolean;
  currentParticipant: Participant;
}

const initialState: ParticipantsReducerState = {
  participants: {},
  participantsLoaded: false,
  currentParticipant: null,
};

export default (state = initialState, action): ParticipantsReducerState => {
  switch (action.type) {
    case getType(SetParticipantsList): {
      const participants = action.payload.reduce(
        (result, participant: Participant) => {
          result[participant.id] = participant;
          return result;
        },
        {}
      );

      return { ...state, participants: participants, participantsLoaded: true };
    }
    case getType(AddParticipant): {
      const participant = action.payload;
      return {
        ...state,
        participants: {
          ...state.participants,
          [participant.id]: { ...participant },
        },
      };
    }
    case getType(SetCurrentParticipant): {
      const participant = action.payload;
      return { ...state, currentParticipant: participant };
    }
    case getType(ResetParticipantsStore): {
      return {
        ...initialState,
      };
    }
    case getType(UpdateParticipant): {
      const participant: Participant = action.payload;

      return {
        ...state,
        currentParticipant: { ...participant },
        participants: {
          ...state.participants,
          [participant.id]: { ...participant },
        },
      };
    }
    case getType(UpdateParticipantSection): {
      const section: ParticipantSection = action.payload;
      const participant = state.currentParticipant;

      const sectionIndex = participant.sections.findIndex(
        (pSection) => section.id === pSection.id
      );
      participant.sections[sectionIndex] = section;

      return {
        ...state,
        currentParticipant: { ...participant },
        participants: {
          ...state.participants,
          [participant.id]: { ...participant },
        },
      };
    }
    case getType(UpdateParticipantTest): {
      const test: ParticipantTest = action.payload;
      const participant = state.currentParticipant;

      if (participant.tests) {
        const testIndex = participant.tests.findIndex(
          (pTest) => test.id === pTest.id
        );
        participant.tests[testIndex] = test;
      } else {
        participant.tests = [test];
      }

      return {
        ...state,
        currentParticipant: { ...participant },
        participants: {
          ...state.participants,
          [participant.id]: { ...participant },
        },
      };
    }
    default:
      return state;
  }
};

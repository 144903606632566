import { AppState } from "@app/store/reducers";
import { createSelector } from "reselect";
import { ParticipantsReducerState } from "@app/modules/courses/store/reducers/participants.reducer";
import {
  Course,
  Module,
  Participant,
  ParticipantTest,
  Section,
} from "@app/types/interfaces";
import { Status } from "@app/types/enums";
import { ParticipantSection } from "@app/types/interfaces/participant-section.interface";

const participantsState = (state: AppState): ParticipantsReducerState =>
  state.participantsReducers.participants;

export const selectParticipantsList = createSelector(
  participantsState,
  (state: ParticipantsReducerState) => state.participants
);

export const selectParticipantsLoading = createSelector(
  participantsState,
  (state: ParticipantsReducerState) => state.participantsLoaded
);

export const selectCurrentParticipant = createSelector(
  participantsState,
  (state: ParticipantsReducerState) => state.currentParticipant
);

export const selectParticipantOfCourseId = (courseId: number) =>
  createSelector(selectParticipantsList, (participants) => {
    if (participants === {}) return null;
    return Object.values(participants).find(
      (participant) => participant.course === courseId
    );
  });

export const selectParticipantSectionsOfModule = (sectionsOfModule: {
  [sectionId: number]: Section;
}) =>
  createSelector(selectCurrentParticipant, (participant) => {
    if (!participant) {
      return [];
    }
    const filteredPSections = participant.sections.filter((pSection) =>
      Object.keys(sectionsOfModule).includes(pSection.section.toString())
    );

    return filteredPSections.sort(
      (pSection, nextPSection) =>
        sectionsOfModule[pSection.section.toString()].order -
        sectionsOfModule[nextPSection.section.toString()].order
    );
  });

export const selectParticipantTestOfModule = (testId: number) =>
  createSelector(selectCurrentParticipant, (participant) => {
    return participant?.tests?.find((test) => test.test === testId);
  });

export const selectModuleStatus = (
  module: Module,
  sectionsOfModule: { [sectionId: number]: Section },
  pTest?: ParticipantTest
) =>
  createSelector(
    selectParticipantSectionsOfModule(sectionsOfModule),
    (sections) => {
      const finishedSectionsLength = sections.filter(
        (section) => section.status === Status.FINISHED
      ).length;
      if (sections.length === 0 && module?.sections?.length)
        return Status.NOT_STARTED;
      if (
        sections.find((section) => section.status === Status.IN_PROGRESS) ||
        (finishedSectionsLength &&
          finishedSectionsLength < Object.keys(sectionsOfModule).length)
      )
        return Status.IN_PROGRESS;
      if (sections.find((section) => section.status === Status.NOT_STARTED))
        return Status.NOT_STARTED;
      return pTest ? pTest.status : Status.FINISHED;
    }
  );

export const selectNbOfDoneModules = (course: Course) =>
  createSelector(selectParticipantOfCourseId(course.id), (participant) => {
    let counter = 0;
    if (!participant) return counter;

    const pSections = participant.sections;
    course.modules.forEach((module) => {
      //since everything is ordered, we can simply verify if the last section in the module is finished => the whole module is finished
      if (module?.sections?.length) {
        const lastSectionOfCurrModule: Section =
          module.sections[module.sections?.length - 1];
        if (
          pSections.find((pS) => pS.section === lastSectionOfCurrModule.id)
            ?.status === Status.FINISHED
        ) {
          counter++;
        }
      } else {
        counter++;
      }
    });
    return counter;
  });

export const selectCurrentParticipantTest = (testId: number) =>
  createSelector(selectCurrentParticipant, (participant) =>
    participant?.tests?.find((test) => test.test === testId)
  );

export const selectContentInProgress = createSelector(
  selectCurrentParticipant,
  (participant: Participant) => {
    const firstParticipantSection = participant?.sections?.find(
      (participantSection: ParticipantSection) =>
        participantSection.status === Status.IN_PROGRESS
    );
    if (firstParticipantSection) {
      return firstParticipantSection;
    }
    const firstTest = participant?.tests?.find(
      (participantTest: ParticipantTest) =>
        participantTest.status === Status.IN_PROGRESS
    );
    if (firstTest) {
      return { ...firstTest, isTest: true };
    }
    return null;
  }
);

export const selectParticipantCourseTest = (course: Course) =>
  createSelector(selectCurrentParticipant, (participant: Participant) => {
    return participant?.tests?.find(
      (participantTest: ParticipantTest) =>
        participantTest.test === course.test.id
    );
  });

import { api } from "@app/api";
import { Course, PaginatedData } from "@app/types/interfaces";

const coursesPath = "/records/course";

const courseQueryParams = {
  datafy_fields:
    "id,title,description,test.id,test.title,test.questions.id,test.questions.question,test.questions.answer.id,test.questions.answer.answer,image.file,modules.id,modules.title,modules.order,modules.course,modules.sections.id,modules.sections.title,modules.sections.content,modules.sections.duration,modules.sections.order,modules.sections.module,modules.test.id,modules.test.title,modules.test.questions.id,modules.test.questions.question,modules.test.questions.answer.id,modules.test.questions.answer.answer",
};

export const getUserCourses = (): Promise<PaginatedData<Course>> => {
  return api.get(`${coursesPath}`, {
    params: courseQueryParams,
  });
};

export const getCourse = (courseId: number): Promise<Course> => {
  return api.get(`${coursesPath}/${courseId}`, {
    params: courseQueryParams,
  });
};

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Colors, Routes } from "@app/types/enums";
import { useNavigate } from "react-router-dom";

export const DeletedParticipant = () => {
  const navigate = useNavigate();
  const onButtonClick = () => {
    navigate(Routes.COURSES);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        textAlign={"center"}
        variant={"h1"}
        color={Colors.TEXT_SECONDARY}
      >
        Administratorul v-a sters progresul acestui curs
      </Typography>
      <Button
        onClick={onButtonClick}
        variant="contained"
        color="primary"
        sx={{
          maxWidth: "306px",
          alignSelf: "center",
          mt: 2,
          px: 4,
          py: 1,
        }}
      >
        <Typography
          variant={"button"}
          sx={{
            textTransform: "none",
            color: Colors.WHITE,
            pr: 2,
          }}
          fontWeight={700}
        >
          Întoarce-te la lista de cursuri
        </Typography>
      </Button>
    </Box>
  );
};

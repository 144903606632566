import React from "react";
import { CoursesDisplay } from "@app/modules/courses/components";
import {
  useLoadCourses,
  useLoadParticipants,
} from "@app/modules/courses/hooks";
import { useSelector } from "react-redux";
import {
  selectAvailableCourses,
  selectStartedCourses,
} from "@app/modules/courses/store/selectors";
export const Courses = () => {
  const { loadingCourses } = useLoadCourses();
  const { loadingParticipants } = useLoadParticipants();
  const availableCourses = useSelector(selectAvailableCourses);
  const startedCourses = useSelector(selectStartedCourses);

  return (
    <CoursesDisplay
      availableCourses={availableCourses}
      startedCourses={startedCourses}
      loading={loadingCourses || loadingParticipants}
    />
  );
};

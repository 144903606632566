export enum Colors {
  PRIMARY = "#004771",
  PRIMARY_DARK = "#002B44",
  PRIMARY_LIGHT = "#0076BC",
  SECONDARY = "#5EC094",
  SECONDARY_DARK = "#32825E",
  SECONDARY_LIGHT = "#77EBB7",
  ERROR = "#F02011",
  ERROR_DARK = "#AA2011",
  ERROR_LIGHT = "#F66D63",
  SUCCESS = "#38B73D",
  SUCCESS_DARK = "#218925",
  SUCCESS_LIGHT = "#8DDD90",
  TEXT_PRIMARY = "#14202A",
  TEXT_SECONDARY = "#6F8394",
  DISABLED = "#A6B8C6",

  INFO_MAIN = "#2196F3",
  LIGHT_GREY = "#F5F5F5",
  DARK_GREY = "#969798",
  WHITE = "#fff",
  BLACK = "#000",
  GREY_100 = "#F5F5F5",
  GREY_200 = "#EEEEEE",
  GREY_300 = "#E0E0E0",
  GREY_400 = "#BDBDBD",
  NOT_STARTED = "#A6B8C6",
}

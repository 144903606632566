import React from "react";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { Colors, Routes } from "@app/types/enums";
import { Course } from "@app/types/interfaces";
import { useSelector } from "react-redux";
import {
  selectCourseDuration,
  selectNbOfDoneModules,
  selectParticipantOfCourseId,
} from "@app/modules/courses/store/selectors";
import { useNavigate } from "react-router-dom";
import { CourseDurationDetails } from "@app/common/components";
import { finalizedCourse, formatDuration } from "@app/common/utilities";

interface CourseCardProps {
  course: Course;
}

export const CourseCard = ({ course }: CourseCardProps) => {
  const nbModules = course.modules.length;
  const totalDuration = useSelector(selectCourseDuration(course.id));
  const doneModules = useSelector(selectNbOfDoneModules(course));
  const participant = useSelector(selectParticipantOfCourseId(course.id));
  const remainingNbModules = nbModules - doneModules;
  const durationDone = participant ? participant.duration : 0;
  const progress = participant ? participant.progress : 0;
  const remainingDuration = totalDuration - durationDone;
  const started = !!participant;
  const finalized = finalizedCourse(participant, course);

  const navigate = useNavigate();

  const displayRemainingNbModules = started && !finalized && (
    <span>
      | &nbsp;
      <span style={{ color: Colors.SUCCESS, paddingLeft: 2 }}>
        {remainingNbModules} rămas{remainingNbModules > 1 ? "e" : " "}
      </span>
    </span>
  );
  const displayRemainingDuration = started && !finalized && (
    <span>
      | &nbsp;
      <span style={{ color: Colors.SUCCESS, paddingLeft: 2 }}>
        {formatDuration(remainingDuration)} rămase
      </span>
    </span>
  );

  return (
    <Box
      className="shadow"
      sx={{
        width: "340px",
        height: "407px",
        backgroundColor: Colors.WHITE,
        display: "flex",
        flexDirection: "column",
        borderRadius: 3,
      }}
    >
      <Box
        className={"prevent-text-highlight"}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          backgroundImage: `linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7)), url(${course.image.file})`,
          backgroundSize: "cover",
          height: 300,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        }}
      >
        <Typography
          variant={"subtitle2"}
          style={{
            position: "absolute",
            bottom: 38,
            left: 24,
            zIndex: 1,
            color: Colors.WHITE,
          }}
        >
          Progres:
        </Typography>

        <Box
          sx={{
            height: "10%",
            width: "60%",
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 19,
            left: 24,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              width: "80%",
              height: 4,
              borderRadius: 5,
            }}
          />
          <Typography variant={"caption"} color={Colors.WHITE} sx={{ ml: 0.5 }}>
            {progress}%
          </Typography>
        </Box>

        <Box
          sx={{
            bgcolor: finalized
              ? Colors.SUCCESS
              : started
              ? Colors.SECONDARY
              : Colors.LIGHT_GREY,
            color: started || finalized ? Colors.WHITE : Colors.PRIMARY,
            width: "fit-content",
            maxWidth: 80,
            px: 1,
            py: 0.5,
            borderRadius: 2,
            position: "absolute",
            zIndex: 1,
            bottom: 16,
            right: 24,
          }}
        >
          <span className={"tag limit-rows-to-1"}>
            {finalized ? "Finalizat" : started ? "Început" : "Neînceput"}
          </span>
        </Box>
      </Box>

      <Box
        sx={{
          borderRadius: 3,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: "100%",
          display: "flex",
          p: 3,
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography variant={"h2"} color={Colors.TEXT_PRIMARY}>
            {course.title}
          </Typography>
        </Box>
        <CourseDurationDetails
          nbModules={nbModules}
          totalDuration={totalDuration}
          displayRemainingDuration={displayRemainingDuration}
          displayRemainingNbModules={displayRemainingNbModules}
        />

        <Button
          onClick={() => navigate(Routes.COURSES + "/" + course.id)}
          variant="contained"
          color={finalized ? "secondary" : "primary"}
          sx={{
            maxWidth: "200px",
          }}
        >
          {finalized
            ? "Vizualizează cursul"
            : started
            ? "Continuă cursul"
            : "Află mai multe"}
        </Button>
      </Box>
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import { Answer, ParticipantTest, Test } from "@app/types/interfaces";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  Typography,
} from "@mui/material";
import { Colors, Status } from "@app/types/enums";
import { TestFinished } from "@app/modules/courses/components";
import { useParticipantTests } from "@app/modules/courses/hooks";
import { useSelector } from "react-redux";
import { selectCurrentParticipant } from "@app/modules/courses/store/selectors";
import { usePrompt } from "@app/common/utilities";
import CircularProgress from "@mui/material/CircularProgress";
import { ParticipantSection } from "@app/types/interfaces";
import { CheckboxInput } from "@app/modules/courses/components/checkbox";

interface TestDisplayProps {
  pTest: ParticipantTest;
  test: Test;
  onNextChapterButtonClick: () => void;
  answers: { [questionId: number]: number[] };
  setAnswers: (answers: { [questionId: number]: number[] }) => void;
  submitted: boolean;
  setSubmitted: (submitted: boolean) => void;
  buttonLoading: boolean;
  nextContent: ParticipantTest | ParticipantSection;
}

export const TestDisplay = ({
  pTest,
  test,
  onNextChapterButtonClick,
  answers,
  setAnswers,
  submitted,
  setSubmitted,
  buttonLoading,
  nextContent,
}: TestDisplayProps) => {
  const [disabled, setDisabled] = useState(true);
  const [counter, setCounter] = useState(0);
  const [doneTest, setDoneTest] = useState<ParticipantTest>(null);
  const { setParticipantAnswers } = useParticipantTests();

  const participant = useSelector(selectCurrentParticipant);
  const [isBlocking, setIsBlocking] = useState(false);

  usePrompt(
    "Sigur vreţi să părăsiţi pagina? Sunteţi în cursul unui test!",
    isBlocking
  );

  useEffect(
    () => () =>
      window.history.pushState(
        {
          showConfirmation: false,
        },
        ""
      ),
    []
  );

  useEffect(() => {
    const _test = participant.tests?.find((_test) => _test.test === test.id);
    setCounter(counter + 1);
    if (_test?.status === Status.FINISHED) {
      setDoneTest(_test);
      setIsBlocking(false);
      window.history.pushState(
        {
          showConfirmation: false,
        },
        ""
      );
    } else {
      setDoneTest(null);
      if (Object.values(answers).length !== 0) {
        setIsBlocking(true);
        window.history.pushState(
          {
            showConfirmation: true,
          },
          ""
        );
      }
    }
  }, [test]);

  useEffect(() => {
    if (
      participant.tests?.find((test) => test.test === test.id)
        ?.participantAnswers?.length !== 0 &&
      Object.keys(answers).length === test.questions.length
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [test, answers]);

  const handleChange = (event, questionId: number) => {
    setIsBlocking(true);
    window.history.pushState(
      {
        showConfirmation: true,
      },
      ""
    );

    const _answers = { ...answers };
    if (_answers[questionId]?.length) {
      _answers[questionId].push(+event.target.value);
    } else {
      _answers[questionId] = [+event.target.value];
    }
    setAnswers(_answers);
  };

  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);
    setIsBlocking(false);
    window.history.pushState(
      {
        showConfirmation: false,
      },
      ""
    );
    await setParticipantAnswers(test.id, pTest, answers);
    setSubmitted(true);
    setLoading(false);
  };

  const choicesList = (choices: Answer[]) =>
    choices?.map((choice) => {
      const props = doneTest
        ? {
            checked: !!doneTest.participantAnswers.find(
              (answer) => answer.answer === choice.id
            ),
          }
        : {
            checked: false,
          };
      return (
        <CheckboxInput
          disabled={!!doneTest}
          key={choice.id}
          value={choice.id}
          color={doneTest ? Colors.DARK_GREY : Colors.BLACK}
          text={choice.answer}
          {...props}
        />
      );
    });

  const testList = test.questions.map((question, index) => (
    <FormControl key={index} sx={{ mt: 4 }}>
      <Typography
        variant={"body1"}
        color={doneTest ? Colors.DARK_GREY : Colors.BLACK}
        sx={{ pb: 2 }}
      >
        {`${index + 1}. ${question.question}`}
      </Typography>
      <FormGroup onChange={(e) => handleChange(e, question.id)} sx={{ pl: 2 }}>
        {choicesList(question.answer)}
      </FormGroup>
    </FormControl>
  ));

  return (
    <>
      {submitted ? (
        <TestFinished
          onNextChapterButtonClick={onNextChapterButtonClick}
          setSubmitted={setSubmitted}
          setAnswers={setAnswers}
          test={test}
          participant={participant}
          loading={buttonLoading}
          nextContent={nextContent as ParticipantTest | ParticipantSection}
        />
      ) : (
        <>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              overflowX: "scroll",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant={"h2"}
              color={Colors.TEXT_PRIMARY}
              sx={{ pb: 4 }}
            >
              {test.title}
            </Typography>
            <Divider color={Colors.GREY_300} sx={{ width: 113, height: 2 }} />
            <Box
              sx={{
                height: "100%",
                width: "100%",
                overflowX: "scroll",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              {testList}
            </Box>
            <Button
              disabled={disabled || loading}
              onClick={onSubmit}
              variant="contained"
              color="secondary"
              sx={{
                maxWidth: "214px",
                mt: 5,
                px: 2,
                py: 1,
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: Colors.WHITE }} size={"20px"} />
              ) : (
                <Typography
                  variant={"button"}
                  sx={{
                    textTransform: "none",
                    color: !disabled ? Colors.WHITE : Colors.DARK_GREY,
                    pr: 2,
                  }}
                  fontWeight={700}
                >
                  Confirmă răspunsuri
                </Typography>
              )}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Colors, Routes } from "@app/types/enums";
import { Course, Participant } from "@app/types/interfaces";
import { CourseDurationDetails } from "@app/common/components";
import { useNavigate } from "react-router-dom";
import { useParticipantSections } from "@app/modules/courses/hooks";
import CircularProgress from "@mui/material/CircularProgress";

interface CourseProfileOverviewProps {
  participant: Participant;
  finalized: boolean;
  totalDuration: number;
  nbModules: number;
  course: Course;
}

export const CourseProfileOverview = ({
  participant,
  finalized,
  totalDuration,
  nbModules,
  course,
}: CourseProfileOverviewProps) => {
  const navigate = useNavigate();
  const { initializeParticipantForNewlyCreatedCourse } =
    useParticipantSections();

  const started = !!participant;

  const [loading, setLoading] = useState(false);
  const onButtonClick = async () => {
    setLoading(true);
    if (
      course.modules.length !== 0 &&
      course.modules.filter((module) => module.sections.length !== 0).length !==
        0
    ) {
      if (!started) {
        console.warn("A new participant is being created");
        await initializeParticipantForNewlyCreatedCourse();
      }
      setLoading(false);
      navigate(Routes.COURSES + Routes.START + "/" + course.id);
    } else setLoading(false);
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRight: `2px solid ${Colors.GREY_300}`,
        p: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant={"h2"} color={Colors.TEXT_PRIMARY} sx={{ pb: 3 }}>
            {course.title}
          </Typography>
          <Typography
            variant={"body1"}
            color={Colors.TEXT_PRIMARY}
            sx={{ my: 3, maxHeight: "16vw", overflowY: "scroll" }}
          >
            {course.description}
          </Typography>
        </Box>

        {/*COMPUTED INFO*/}
        <CourseDurationDetails
          nbModules={nbModules}
          totalDuration={totalDuration}
        />
      </Box>

      <Button
        disabled={loading}
        type="submit"
        variant="contained"
        color={finalized ? "secondary" : "primary"}
        onClick={onButtonClick}
        sx={{
          maxWidth: "200px",
        }}
      >
        {loading ? (
          <CircularProgress sx={{ color: Colors.WHITE }} size={"20px"} />
        ) : finalized ? (
          "Vizualizează cursul"
        ) : started ? (
          "Continuă cursul"
        ) : (
          "Începe cursul"
        )}
      </Button>
    </Box>
  );
};

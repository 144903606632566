import { combineReducers } from "redux";
import { authReducers, AuthState } from "@app/modules/auth/store/reducers";
import {
  coursesReducers,
  CoursesState,
} from "@app/modules/courses/store/reducers";
import { coreReducers, CoreState } from "@app/modules/core/store/reducers";

export interface AppState {
  authReducers: AuthState;
  coursesReducers: CoursesState;
  participantsReducers: CoursesState;
  coreReducers: CoreState;
}
export const reducers = combineReducers({
  authReducers: authReducers,
  coursesReducers: coursesReducers,
  participantsReducers: coursesReducers,
  coreReducers: coreReducers,
});

export enum Icons {
  BOOK = "/icons/book-icon.svg",
  CLOCK = "/icons/clock.svg",
  BACK_ARROW = "/icons/back-arrow.svg",
  UP_ARROW = "/icons/up-arrow.svg",
  DOWN_ARROW = "/icons/down_arrow.svg",
  RIGHT_ARROW = "/icons/right-arrow.svg",
  EXIT = "/icons/exit-icon.svg",
  NOT_STARTED = "/icons/not-started-icon.svg",
  FINISHED_MODULE = "/icons/finished-module.svg",
  NOT_VISIBLE = "/icons/not-visible.svg",
  VISIBLE = "/icons/visible.svg",
  DOCUMENT = "/icons/test-icon.svg",
}

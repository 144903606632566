import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import { Colors, Icons } from "@app/types/enums";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { TextInput } from "@app/common/components";
import { EMAIL } from "@app/constants";
import { LoginFormData } from "@app/types/interfaces";

interface LoginPageProps {
  onAuthenticate: (data: LoginFormData) => void;
  message: string;
  loading: boolean;
}

export const LoginPageDisplay = ({
  onAuthenticate,
  message,
  loading,
}: LoginPageProps) => {
  const [visible, setVisible] = useState(false);

  const { control, handleSubmit } = useForm({
    mode: "onTouched",
  });

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: Colors.LIGHT_GREY,
      }}
      style={{
        backgroundImage: `url(/images/login-background.svg)`,
        backgroundPosition: "left",
        backgroundSize: "cover",
      }}
      className={"prevent-text-highlight"}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "80vw",
        }}
      >
        {/*---------------------COVER - LEFT-------------------*/}
        <Box
          sx={{
            width: { md: "50%", lg: "40%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img src={"/images/cover-neets.svg"} />
        </Box>

        {/*-------------------FORM - RIGHT-----------------------*/}
        <Box
          sx={{
            borderRadius: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: 616,
            width: 486,
            bgcolor: "white",
            px: 13,
            py: 10,
            color: Colors.WHITE,
          }}
        >
          <Box>
            <Typography variant="h2" color={Colors.PRIMARY}>
              Autentifică-te în contul tău
            </Typography>
            <Typography
              variant="subtitle2"
              color={Colors.DARK_GREY}
              sx={{ mt: 2, mb: 2.5 }}
            >
              Asigură-te că toate datele sunt introduse corect.
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(onAuthenticate)}>
            <Box>
              <TextInput
                disabled={loading}
                control={control}
                name={"email"}
                label={"Email"}
                type="text"
                rules={{
                  required: "Email-ul este obligatoriu",
                  pattern: {
                    value: EMAIL,
                    message: "Email-ul este gresit",
                  },
                }}
                style={{ marginBottom: "8%", width: "100%" }}
              />
              <Box>
                <Controller
                  name={"password"}
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled={loading}
                      label="Parolă"
                      type={visible ? "text" : "password"}
                      variant="filled"
                      error={!!error}
                      onBlur={onBlur}
                      value={value}
                      onChange={onChange}
                      helperText={error ? error.message : null}
                      autoComplete="off"
                      sx={{ width: 1 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setVisible(!visible)}
                            onMouseDown={(e) => e.preventDefault()}
                            sx={{ display: loading ? "none" : "flex" }}
                          >
                            <IconButton>
                              {visible ? (
                                <img
                                  src={Icons.NOT_VISIBLE}
                                  alt={"not-visible"}
                                />
                              ) : (
                                <img src={Icons.VISIBLE} alt={"visible"} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  rules={{
                    required: "Parola e obligatorie",
                  }}
                />
              </Box>

              {message.length !== 0 && (
                <Typography
                  variant="body2"
                  color={Colors.ERROR}
                  sx={{ display: "flex", justifyContent: "start", py: 2 }}
                >
                  {message}
                </Typography>
              )}

              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: `${!message.length && "16%"}`,
                    px: 3,
                    py: 2,
                    width: 1,
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{ color: Colors.WHITE, mb: -0.5 }}
                      size={"20px"}
                    />
                  ) : (
                    "Autentificare"
                  )}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

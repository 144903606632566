import React from "react";
import { Course } from "@app/types/interfaces";
import { Box, Divider, Typography } from "@mui/material";
import { Colors, Icons, Status } from "@app/types/enums";
import { useSelector } from "react-redux";
import {
  selectCurrentParticipantTest,
  selectCurrentSections,
  selectModuleDurationOfCurrentCourse,
  selectModuleStatus,
  selectParticipantTestOfModule,
} from "@app/modules/courses/store/selectors";
import { formatDuration } from "@app/common/utilities";

interface LearningProgramOverviewProps {
  course: Course;
}

export const LearningProgramOverview = ({
  course,
}: LearningProgramOverviewProps) => {
  const modulesList = course.modules.map((module, index) => {
    const currentSectionsOfModule = useSelector(selectCurrentSections)[
      module.id
    ];

    const moduleTest = useSelector(
      selectParticipantTestOfModule(module.test.id)
    );
    const moduleStatus = useSelector(
      selectModuleStatus(
        module,
        currentSectionsOfModule,
        moduleTest && moduleTest
      )
    );

    return (
      <Box
        key={index}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "start",
          mb: 3,
        }}
      >
        <img
          src={`${
            moduleStatus === Status.IN_PROGRESS
              ? Icons.RIGHT_ARROW
              : moduleStatus === Status.NOT_STARTED
              ? Icons.NOT_STARTED
              : Icons.FINISHED_MODULE
          }`}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            pl: 1,
          }}
        >
          <Typography variant="h3" color={Colors.TEXT_PRIMARY} sx={{ pb: 1 }}>
            {module.title}
          </Typography>
          <Typography variant={"caption"} color={Colors.TEXT_PRIMARY}>
            Număr de capitole: {module.sections.length}
          </Typography>
          <Typography variant={"caption"} color={Colors.TEXT_PRIMARY}>
            Durata de pregătire:{" "}
            {formatDuration(
              useSelector(selectModuleDurationOfCurrentCourse(module.id))
            )}
          </Typography>
        </Box>
      </Box>
    );
  });

  const participantCourseTest = useSelector(
    selectCurrentParticipantTest(course.test.id)
  );

  return (
    <Box
      sx={{
        p: 6,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography variant={"subtitle1"} color={Colors.TEXT_PRIMARY}>
        Program de învățare
      </Typography>
      <Divider color={Colors.GREY_300} sx={{ width: 113, height: 2, mt: 4 }} />
      <Box
        sx={{
          pt: 4,
          ml: 4,
          width: "auto",
          height: "inherit",
          overflowY: "scroll",
        }}
      >
        {modulesList}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "start",
            mb: 3,
          }}
        >
          <img
            src={`${
              participantCourseTest?.status === Status.IN_PROGRESS
                ? Icons.RIGHT_ARROW
                : !participantCourseTest ||
                  participantCourseTest.status === Status.NOT_STARTED
                ? Icons.NOT_STARTED
                : Icons.FINISHED_MODULE
            }`}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              pl: 1,
            }}
          >
            <Typography color={Colors.TEXT_PRIMARY} sx={{ pb: 1 }}>
              <span className={"limit-rows-to-2 sidenav-text"}>
                {course.test.title}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { Course, Module, Participant, Section } from "@app/types/interfaces";
import { Colors, Status } from "@app/types/enums";
import { uniqBy } from "lodash";

export const formatDuration = (duration: number) => {
  return duration < 60
    ? duration + " minute "
    : Math.floor(duration / 60) + " ore și " + (duration % 60) + " minute ";
};

export const calculateDurationOfModule = (module: Module) => {
  return module.sections.reduce((sectionDuration, section: Section) => {
    return sectionDuration + section.duration;
  }, 0);
};

export const calculateDurationOfCourse = (course: Course) => {
  return course?.modules.reduce((totalModuleDuration, module: Module) => {
    return totalModuleDuration + calculateDurationOfModule(module);
  }, 0);
};

export const createSectionsArr = (course: Course) => {
  return course.modules.reduce((result, module: Module) => {
    result[module.id] = module.sections.reduce(
      (sectionResult, section: Section) => {
        sectionResult[section.id] = section;
        return sectionResult;
      },
      {}
    );
    return result;
  }, {});
};

export const getCorrectColor = (status: Status, isCurrent: boolean) => {
  return status === Status.IN_PROGRESS
    ? Colors.PRIMARY_LIGHT
    : status === Status.NOT_STARTED
    ? Colors.NOT_STARTED
    : isCurrent
    ? Colors.TEXT_PRIMARY
    : Colors.SUCCESS;
};

export const finalizedCourse = (participant: Participant, course: Course) => {
  const tests = course?.modules
    .filter((module: Module) => !!module.test)
    .map((_module: Module) => _module.test);
  if (course?.test) {
    tests.push(course.test);
  }
  return (
    participant?.progress === 100 &&
    participant?.tests?.filter((test) => test.status !== Status.FINISHED)
      .length === 0 &&
    participant?.tests?.length === uniqBy(tests, "id")?.length
  );
};

// https://docs.google.com/forms/d/e/1FAIpQLScNvjWoifI5GylIwawumHg5fCnJSEvUY1Jti6GD9H3NFyX6Ag/viewform?usp=sf_link

import { Token, User } from "@app/types/interfaces";
import { setTokens, removeTokens, api } from "@app/api";

const logInPath = "/auth/signIn";
const userPath = "/auth/user";

const userParams = {
  datafy_fields: "id,email,admin,disabled,first_name,last_name",
};

export const login = async (email: string, password: string) => {
  const token = await api.post<void, Token>(logInPath, {
    email,
    password,
  });
  setTokens(token);
  return getUser();
};

export const getUser = (): Promise<User> => {
  return api.get(userPath, {
    params: userParams,
  });
};

export const logout = () => {
  removeTokens();
};

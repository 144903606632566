import React, { useState } from "react";
import { CourseCard } from "@app/modules/courses/components";
import { Box, Button, Typography } from "@mui/material";
import { Course } from "@app/types/interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import { Colors } from "@app/types/enums";

import { TitleFormat } from "@app/common/components";

import useWindowDimensions from "@app/modules/core/hooks/use-window-dimensions.hook";

interface CoursesDisplayProps {
  availableCourses: Course[];
  startedCourses: Course[];
  loading: boolean;
}

export const CoursesDisplay = ({
  startedCourses,
  availableCourses,
  loading,
}: CoursesDisplayProps) => {
  const { width } = useWindowDimensions();
  const numberOfCards: number = (width - 320) / 364; //card width + gap
  const [loadMore, setLoadMore] = useState(true);
  const onLoadMore = () => {
    setLoadMore(!loadMore);
  };

  const coursesList = (
    courses: Course[],
    noItemsMessage: string,
    hasLoadMore: boolean
  ) => {
    if (courses.length !== 0) {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              height: hasLoadMore && loadMore ? "407px" : "100%",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "start",
              flexWrap: "wrap",
              overflow: hasLoadMore && loadMore ? "hidden" : "none",
              gap: "24px",
            }}
          >
            {courses.map((course, index) => {
              return <CourseCard key={index} course={course} />;
            })}
          </Box>
          {hasLoadMore && courses.length >= numberOfCards && (
            <Box
              className="full-width"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  maxWidth: "150px",
                  mb: 2,
                  mt: 4,
                }}
                onClick={onLoadMore}
              >
                {loadMore ? "Vezi mai multe" : "Vezi mai puține"}
              </Button>
            </Box>
          )}
        </>
      );
    }

    return (
      <Box
        sx={{
          height: "69px",
          width: "548px",
          backgroundColor: Colors.WHITE,
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          pl: 3,
        }}
      >
        <Typography variant="body1">{noItemsMessage}</Typography>
      </Box>
    );
  };

  return loading ? (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress sx={{ color: Colors.SECONDARY }} size={"56px"} />
    </Box>
  ) : (
    <Box
      className="layout-container"
      sx={{
        width: "100vw",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        pb: 9,
        pt: 5,
      }}
    >
      <TitleFormat
        title={"Cursurile mele"}
        subtitle={"Aici sunt cursurile la care ai aplicat"}
      />
      {coursesList(
        startedCourses,
        "Încă nu ai selectat niciun curs. Alege unul de mai jos și aplică acum.",
        true
      )}
      <TitleFormat
        title={"Cursuri disponibile"}
        subtitle={"Aici sunt cursurile disponibile la care poți aplica"}
        mt={4}
      />
      {coursesList(
        availableCourses,
        "Momentan nu există niciun curs disponibil în platformă.",
        false
      )}
    </Box>
  );
};

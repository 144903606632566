import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors, Icons } from "@app/types/enums";
import { formatDuration } from "@app/common/utilities";

interface CourseDurationDetailsProps {
  nbModules: number;
  totalDuration: number;
  displayRemainingNbModules?: JSX.Element;
  displayRemainingDuration?: JSX.Element;
}

export const CourseDurationDetails = ({
  nbModules,
  totalDuration,
  displayRemainingNbModules,
  displayRemainingDuration,
}: CourseDurationDetailsProps) => {
  return (
    <Box
      sx={{
        height: "64px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "start",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          className={"prevent-text-highlight"}
          src={Icons.BOOK}
          alt={"book-icon"}
        />
        <Typography
          variant={"body2"}
          color={Colors.TEXT_PRIMARY}
          sx={{ pl: 2, pr: 1 }}
        >
          {`${nbModules} Modul${nbModules > 1 ? "e" : " "}`}
          {displayRemainingNbModules}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: 5,
        }}
      >
        <img
          className={"prevent-text-highlight"}
          src={Icons.CLOCK}
          alt={"clock-icon"}
        />
        <Typography
          variant={"body2"}
          color={Colors.TEXT_PRIMARY}
          sx={{ pl: 2, pr: 1 }}
        >
          {`Durata de  ${formatDuration(totalDuration)}`}
          {displayRemainingDuration}
        </Typography>
      </Box>
    </Box>
  );
};

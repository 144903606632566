import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Colors, Icons, Routes } from "@app/types/enums";
import { PlaceholderImage } from "@app/modules/core/components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@app/auth/store/selectors";
import {
  ResetCoursesStore,
  ResetParticipantsStore,
} from "@app/modules/courses/store/actions";
import { useAuth } from "@app/auth/hooks";

export const Header = () => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(ResetCoursesStore());
    dispatch(ResetParticipantsStore());
    logout();
  };

  return (
    <Box
      className="layout-container full-width shadow"
      sx={{
        height: "88px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: Colors.WHITE,
        position: "sticky",
        top: 0,
        zIndex: 2,
      }}
    >
      <img src={"/logos/logos.svg"} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Button
          variant="text"
          onClick={() => navigate(Routes.PROFILE)}
          sx={{ pr: "4px !important" }}
          endIcon={
            <PlaceholderImage
              firstname={user.firstName}
              lastname={user.lastName}
            />
          }
        >
          <Typography
            className={"limit-rows-to-1"}
            variant="caption"
            sx={{
              margin: 0,
              color: Colors.TEXT_PRIMARY,
              overflow: "hidden",
              paddingLeft: "16px",
            }}
          >
            {user.firstName + " " + user.lastName}
          </Typography>
        </Button>
        <Box
          sx={{
            height: "32px",
            width: 2,
            backgroundColor: Colors.GREY_300,
            mx: 2,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          endIcon={<img src={Icons.EXIT} alt={"exit"} />}
          onClick={() => {
            if (
              window.history.state.showConfirmation &&
              !window.confirm(
                "Sigur vreţi să părăsiţi pagina? Aveţi modificări nesalvate!"
              )
            ) {
              return;
            }
            onLogout();
          }}
        >
          Ieși din cont
        </Button>
      </Box>
    </Box>
  );
};

import { LoginPage } from "@app/modules/auth/containers";
import { Routes } from "@app/types/enums";
import { Profile } from "@app/modules/profile/containers";
import {
  CourseProfile,
  StartCourse,
  Courses,
} from "@app/modules/courses/containers";
import { NoAccess } from "@app/common/components";

export const publicRoutes = [
  {
    key: "login",
    path: Routes.LOGIN,
    component: LoginPage,
  },
  {
    key: "no-access",
    path: Routes.NO_ACCESS,
    component: NoAccess,
  },
];

export const privateRoutes = [
  {
    key: "my-courses",
    path: Routes.COURSES,
    component: Courses,
  },
  {
    key: "profile",
    path: Routes.PROFILE,
    component: Profile,
  },
  {
    key: "course-profile",
    path: Routes.COURSES + "/:id",
    component: CourseProfile,
  },
  {
    key: "course",
    path: Routes.COURSES + Routes.START + "/:id",
    component: StartCourse,
  },
];

import { WENS_API } from "@app/constants";
import axios from "axios";

// TODO Add authentication header after apis will be authenticated
export const wensApi = axios.create({
  baseURL: WENS_API,
  headers: {
    "Content-Type": "application/json",
  },
});

export default wensApi;

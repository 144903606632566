import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectIsAuth } from "@app/modules/auth/store/selectors";
import { useLocation } from "react-router-dom";
import { Routes } from "@app/types/enums";
import { Layout } from "@app/modules/core/containers";

interface RouteProps {
  component: FunctionComponent;
}

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const isAuth = useSelector(selectIsAuth);
  const { pathname } = useLocation();

  return isAuth ? (
    <Layout>
      <Component {...rest} />
    </Layout>
  ) : (
    <Navigate to={Routes.LOGIN} state={{ returnUrl: pathname }} />
  );
};

export default PrivateRoute;

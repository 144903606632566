import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { Colors } from "@app/types/enums";

export const Footer = () => {
  const projectText: string =
    "Proiect cofinanțat din Fondul Social European prin \nProgramul Operațional Capital Uman 2014 -2020";
  const priorityAxisText: string =
    "Axa prioritară 2: îmbunătățirea \nsituației tinerilor din categoria NEETs";

  return (
    <Box
      className="layout-container full-width"
      sx={{
        height: "216px",
        backgroundColor: Colors.WHITE,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        className="full-width"
        sx={{
          height: "216px",
          backgroundColor: Colors.WHITE,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" color={Colors.TEXT_PRIMARY}>
          Șanse pentru tinerii NEET’s
        </Typography>
        <img src={"/logos/logos.svg"} />
      </Box>

      <Stack
        className="full-width"
        direction="row"
        justifyContent={"start"}
        alignItems={"center"}
        gap={2}
        divider={
          <Divider
            orientation="vertical"
            sx={{
              borderColor: Colors.GREY_100,
              height: "48px",
              display: "block",
            }}
            flexItem
          />
        }
        pb={2}
        sx={{
          color: "common.white",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography
          variant="body2"
          color={Colors.TEXT_SECONDARY}
          sx={{ whiteSpace: "break-spaces" }}
        >
          {projectText}
        </Typography>
        <Typography
          variant="body2"
          color={Colors.TEXT_SECONDARY}
          sx={{ whiteSpace: "break-spaces" }}
        >
          {priorityAxisText}
        </Typography>
        <Typography variant="body2" color={Colors.TEXT_SECONDARY}>
          Titlu proiect: Șanse pentru tinerii NEETs
        </Typography>
        <Typography variant="body2" color={Colors.TEXT_SECONDARY}>
          Cod SMIS: 150883
        </Typography>
      </Stack>
    </Box>
  );
};
